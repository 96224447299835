import React, { FunctionComponent, useEffect, useState } from 'react';
import ManagersPage, { Row } from '../../view/component/page/ManagersPage';
import { CallabtSDK } from 'callabt-sdk';
import PageWrapper from '../../lib/PageWrapper';

const Managers: FunctionComponent = () => {
  const [rows, setRows] = useState<Row[]>([]);
  const fetch = async () => {

    const { managers } = await CallabtSDK.getManagers();

    setRows(managers);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <PageWrapper>
      <ManagersPage
        rows={rows}
      />
    </PageWrapper>
  );
};

export default Managers;
